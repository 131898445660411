abbr.lexicon-indicator,
acronym.lexicon-indicator,
sup.lexicon-indicator,
span.lexicon-indicator,
a.lexicon-indicator,
a.lexicon-icon,
dfn.lexicon-indicator,
img.lexicon-indicator {
  cursor: help;
}
span.lexicon-term {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

a.lexicon-term {
  text-decoration: none;
  border-bottom: 1px dotted #4eb856;
  color: #4eb856;
  cursor: help;

  &:link {
   text-decoration: none;
  }
  &:hover {
    border-bottom-style: solid;
  }
}
.lexicon-links {
  text-align: center;
  background-color: #EEF9FB;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 5px;

  a {
    font-size: 140%;
    text-transform: uppercase;
  }
}

.lexicon-extralinks a{
  background-color: #cccccc;
  font-size: 0.6em;
  padding: 3px;
  font-weight: normal;
}

.lexicon-list {
  dt {
  font-weight: bold;
  color: #1f4ea1;
  }
  dd {
    margin-left: 20px;
  }
}

.lexicon-description {
  margin-bottom: 0.75em;
}
